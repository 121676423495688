import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GaleriaComponent } from './pages/galeria/galeria.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { PaquetesComponent } from './pages/paquetes/paquetes.component';
import { ServiciosComponent } from './pages/servicios/servicios.component';
import { HomeComponent } from './pages/home/home.component';

const app_routes: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'galeria', component: GaleriaComponent },
    { path: 'about', component: AboutComponent },
    { path: 'contacto', component: ContactoComponent },
    { path: 'paquetes', component: PaquetesComponent },
    { path: 'servicios', component: ServiciosComponent },
    { path: '**', pathMatch: 'full', redirectTo: 'home' }
];

@NgModule ( {
    imports: [
        RouterModule.forRoot( app_routes, { useHash: true} )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {

}
