import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GalleryInterface } from '../interfaces/gallery.interface';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  cargando = true;
  galleryArray: any[] = [];

  constructor(private http: HttpClient ) {
    this.cargarGallery();
  }

  private cargarGallery() {
    this.http.get('https://jsmakeup-baf81.firebaseio.com/gallery.json')
        .subscribe( ( resp: any[]) => {
          this.galleryArray = resp;

          setTimeout(() => {
            this.cargando = false;
          }, 200);

        });
  }
}
