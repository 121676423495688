import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InfoPagina } from '../interfaces/info-pag.interface';

@Injectable({
  providedIn: 'root'
})
export class InfoPagService {

  info: InfoPagina = {};
  cargada = false;
  about: any[] = [];

  constructor(private http: HttpClient) {

     this.cargarInfo();
     this.cargarAbout();
  }

  private cargarInfo() {
    this.http.get('assets/Data/data-page.json')
    .subscribe( (resp: InfoPagina) => {

    this.cargada = true;
    this.info = resp;
    } );
  }

  private cargarAbout() {
    this.http.get('https://jsmakeup-baf81.firebaseio.com/about.json')
    .subscribe( (resp: any[]) => {

      this.about = resp;

    });
  }
}
